import { PropsWithChildren } from 'react'
import { DisplayTrafficControl } from './DisplayTrafficControl'
import styles from './Controls.module.scss'
import { ControlStrip } from '../../UI/ControlStrip/ControlStrip'
import { DefaultPortControl, DefaultPortControlProps } from './DefaultPortControl'

type PortcallControlsProps = PropsWithChildren<{
  port: DefaultPortControlProps
}>

export const TrafficAndPortcallsControls = ({ port, children }: PortcallControlsProps) => (
  <div className={styles.mapControlStrip}>
    <ControlStrip>
      <DefaultPortControl {...port} />
      <DisplayTrafficControl />
      {children}
    </ControlStrip>
  </div>
)
