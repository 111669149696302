export const TrafficVesselIcon = () => (
  <svg
    width="17px"
    height="27px"
    viewBox="0 0 17 27"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Rectangle Copy 5</title>
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-2">
        <stop stopColor="#FCBD51" offset="0%"></stop>
        <stop stopColor="#F39900" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g id="Page-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="02b-Default-Port-"
        transform="translate(-735.000000, -588.000000)"
        fill="url(#linearGradient-2)"
        stroke="#B27000"
      >
        <g id="right" transform="translate(329.000000, -15.000000)">
          <g id="explain" transform="translate(36.000000, 397.000000)">
            <g id="Group-2-Copy-3" transform="translate(0.000000, 156.000000)">
              <g
                id="vessel"
                transform="translate(378.500000, 50.000000) rotate(90.000000) translate(-378.500000, -50.000000) translate(351.000000, 5.000000)"
              >
                <g
                  id="green-XL"
                  transform="translate(27.500000, 45.000000) rotate(-90.000000) translate(-27.500000, -45.000000) translate(-17.500000, 17.500000)"
                >
                  <polygon
                    id="Rectangle-Copy-5"
                    points="37 37.946577 45 27.946577 53 37.946577 53 53.946577 45 48.946577 37 53.946577"
                  ></polygon>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const PortcallVesselIcon = () => (
  <svg
    width="17px"
    height="27px"
    viewBox="0 0 17 27"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Rectangle Copy 4</title>
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-3">
        <stop stopColor="#6CCA66" offset="0%"></stop>
        <stop stopColor="#3DA739" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g id="Page-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="02b-Default-Port-"
        transform="translate(-735.000000, -470.000000)"
        fill="url(#linearGradient-3)"
        stroke="#22881E"
      >
        <g id="right" transform="translate(329.000000, -15.000000)">
          <g id="explain" transform="translate(36.000000, 397.000000)">
            <g id="Group-2" transform="translate(0.000000, 34.000000)">
              <g
                id="vessel"
                transform="translate(378.500000, 54.000000) rotate(90.000000) translate(-378.500000, -54.000000) translate(351.000000, 9.000000)"
              >
                <g
                  id="green-XL"
                  transform="translate(27.500000, 45.000000) rotate(-90.000000) translate(-27.500000, -45.000000) translate(-17.500000, 17.500000)"
                >
                  <polygon
                    id="Rectangle-Copy-4"
                    points="37 37.946577 45 27.946577 53 37.946577 53 53.946577 45 48.946577 37 53.946577"
                  ></polygon>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
