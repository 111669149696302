import { MetadataJson, SystemOfMeasurement } from './../../Domain/User'
import { BACKEND_URL, isShowCase } from '../../config'
import { authRequest } from '../auth'
import { Metadata } from '../../Domain/User'

const version = isShowCase ? '' : '/v2'

export const fetchMetadata = (): Promise<Metadata> =>
  authRequest
    .get(`${BACKEND_URL}/api${version}/me/metadata`)
    .json<MetadataJson>()
    .then(metadata => ({
      measurementUnit: SystemOfMeasurement.Metric,
      ...metadata,
    }))
