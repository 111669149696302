import React from 'react'
import classnames from 'classnames'
import { Search, MobileSearch } from '../../QuickShipFinder/Search'
import { Logo } from '../../UI/Logo'
import styles from './Menu.module.scss'
import { AccountButton } from './AccountButton'
import { useResponsiveness } from '../../lib/hooks/useResponsiveness'
import { HamburgerMenu } from '../../UI/HamburgerMenu/HamburgerMenu'
import { Pages } from '../../constants'
import { Link } from 'react-router-dom'
import { VesselSearchResult } from '../../Domain/VesselSearchResult'
import { isShowCase } from '../../config'
import { useAuth0 } from '@auth0/auth0-react'

export const MenuWrapper: React.FC = ({ children }) => <div className={styles.menu}>{children}</div>

type RightMenuProps = {
  isMenuNavigationOpen: boolean
  handleSearchResult: (result: VesselSearchResult, term: string) => void
}

export function RightMenu({ isMenuNavigationOpen, handleSearchResult }: RightMenuProps) {
  const { isAuthenticated } = useAuth0()
  const { isMobile } = useResponsiveness()

  return (
    <div className={styles.right}>
      <>
        {!isMobile && <Search handleSearchResult={handleSearchResult} className={styles.menuItem} />}
        {isMobile && <MobileSearch handleSearchResult={handleSearchResult} className={styles.menuItem} />}
      </>
      {!isMenuNavigationOpen && isShowCase && !isAuthenticated && <AccountButton />}
    </div>
  )
}

type LeftMenuProps = {
  isMenuNavigationOpen: boolean
  setMenuNavigationOpen: (isOpen: boolean) => void
}

export function LeftMenu({ isMenuNavigationOpen, setMenuNavigationOpen }: LeftMenuProps) {
  return (
    <div className={styles.left}>
      <div className={styles.leftItems}>
        <div className={classnames(styles.menuItem, styles.hamburgerItem)}>
          <HamburgerMenu
            isOpen={isMenuNavigationOpen}
            handleClick={() => setMenuNavigationOpen(!isMenuNavigationOpen)}
            customStyle={styles.hamburgerStyle}
          />
        </div>
        <Link to={Pages.MAP} className={classnames(styles.menuItem, styles.logoItem)}>
          <Logo className={styles.logo} />
        </Link>
      </div>
    </div>
  )
}
