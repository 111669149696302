import { pipe, constant } from 'fp-ts/es6/function'
import { getOrElse } from 'fp-ts/es6/Option'
import { useEffect } from 'react'
import { AllVesselProperties, vesselDestinationFromProperties } from '../../Domain/Vessel'
import { useMixpanel } from '../../lib/mixpanel/MixpanelContext'
import { clearSearched, searchedVessel } from '../Traffic/vesselSearch'

export function useTrackSearchedVessel(properties?: AllVesselProperties & { destinationPortName?: string }) {
  const { track, incrementUserProperty } = useMixpanel()

  useEffect(() => {
    if (properties === undefined) {
      return
    }

    const subscription = searchedVessel.subscribe(searched => {
      if (searched) {
        const { mmsi, query } = searched

        if (properties.mmsi.toString() === mmsi) {
          track('Search', {
            searchTerm: query,
            destination: pipe(vesselDestinationFromProperties(properties), getOrElse(constant('n/a'))),
          })
          incrementUserProperty('totalNumberOfSearches', 1)
          clearSearched()
        }
      }
    })

    return subscription.unsubscribe.bind(subscription)
  }, [properties, track, incrementUserProperty])
}
