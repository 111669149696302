import classnames from 'classnames'
import { useTranslation } from '../../lib/i18n'
import { AccountIcon } from '../../UI/Icons/Account'
import MaterialIcon from '../../UI/MaterialIcon'
import { useAuth0 } from '@auth0/auth0-react'
import styles from './Menu.module.scss'
import { DropoutMenu } from '../../UI/DropoutMenu/DropoutMenu'
import { TopRightDropoutMenuDropper } from '../../UI/DropoutMenu/DropoutMenuDropper'
import { isShowCase } from '../../config'
import { useMixpanel } from '../../lib/mixpanel/MixpanelContext'
import { getClickEventName } from '../../lib/mixpanel/eventNames'

export function AccountButton() {
  const { isLoading, loginWithRedirect } = useAuth0()
  const { register, track } = useMixpanel()
  const { t } = useTranslation()

  if (isLoading) {
    return null
  }

  const handleLogin = () => {
    track(getClickEventName('Menu', 'Log in'))
    loginWithRedirect({ max_age: 0 })
  }

  return (
    <DropoutMenu>
      {({ isOpen, closeMenu, openMenu }) => (
        <div
          className={classnames(styles.menuItem, styles.myAccount)}
          onClick={() => {
            isOpen ? closeMenu() : openMenu()
          }}
        >
          <AccountIcon className={styles.accountIcon} />
          <MaterialIcon className={styles.menuHandle} type="expand_more" />
          {isOpen && (
            <TopRightDropoutMenuDropper className={styles.accountMenu} triangleOffsetRight={30}>
              <li className={styles.accountMenuItem} onClick={handleLogin}>
                <MaterialIcon className={styles.icon} type="login" />
                <span className={styles.action}>{t('ShipTracker.Account.Login')}</span>
              </li>
              {isShowCase ? (
                <li
                  className={styles.accountMenuItem}
                  onClick={() => {
                    register({ signupMethod: 'menu' })
                  }}
                >
                  <MaterialIcon className={styles.icon} type="admin_panel_settings" />
                  <span className={styles.label}>{t('ShipTracker.Common.Free')}</span>
                  <span className={styles.action}>{t('ShipTracker.Account.CreateAccountAsNotifyOnArrival')}</span>
                </li>
              ) : null}
            </TopRightDropoutMenuDropper>
          )}
        </div>
      )}
    </DropoutMenu>
  )
}
