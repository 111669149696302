import { useAuth0 } from '@auth0/auth0-react'
import { useMixpanel } from '../mixpanel/MixpanelContext'
import md5 from 'md5'
import { nanoid } from 'nanoid'
import { RefObject, useEffect, useRef } from 'react'
import { isShowCase } from '../../config'
import { store } from '../../dataStore'
import { getMetadataCache, saveMetadataCache } from '../metadataCacheStorage'
import { useFromFetch } from '../useFromFetch'
import { useLocation } from 'react-router-dom'
import { useState } from 'react'
import { Dict } from 'mixpanel-browser'
import { usePrevious } from './usePrevious'

type MetadataTrackingProperties = {
  registrationDate?: string
  port?: string
}

const getMetadataTrackingProperties = async (): Promise<MetadataTrackingProperties> => {
  const metadata = await store.metadata.fetch()

  return {
    registrationDate: metadata.createdAt ? new Date(metadata.createdAt).toISOString() : undefined,
    ...(!isShowCase ? { port: metadata.defaultPort } : {}),
    ...(metadata.measurementUnit ? { measurementUnit: metadata.measurementUnit } : {}),
  }
}

export const useTracking = (email?: string) => {
  const { clearQueue, setupUser, track, setUserProperties, register, identify } = useMixpanel()
  const { user, isAuthenticated } = useAuth0()
  const [metadataProperties, setMetaProperties] = useState<MetadataTrackingProperties>()
  const { isLoading, data } = useFromFetch(getMetadataTrackingProperties)
  const location = useLocation()
  const prevRoute = usePrevious(location.pathname)

  useEffect(() => {
    if (isShowCase || !isAuthenticated) {
      clearQueue()
    }
  }, [clearQueue, isAuthenticated])

  useEffect(() => setMetaProperties(data), [data])
  useEffect(() => {
    if (isAuthenticated) {
      getMetadataTrackingProperties().then(setMetaProperties)
      register({ environment: 'account' })
    } else {
      register({ environment: 'open' })
    }
  }, [isAuthenticated, register])

  useEffect(() => {
    register(getMetadataCache())
    setUserProperties(getMetadataCache())
  }, [register, setUserProperties])

  useEffect(() => {
    if (email && !isLoading && metadataProperties !== undefined) {
      setupUser(md5(email), metadataProperties, ({ track }) => {
        clearQueue()
        saveMetadataCache(cache => ({ ...cache, ...metadataProperties }))
        track('Start session')
      })
    }
  }, [email, clearQueue, setupUser, track, isLoading, metadataProperties, user, register, setUserProperties, identify])

  useEffect(() => {
    const currentRoute = location.pathname
    if (currentRoute !== prevRoute) {
      track('View Page', { pageName: currentRoute })
    }
  }, [track, prevRoute, location])
}

export const useMixpanelTrackExternalLink = <T extends HTMLAnchorElement>(
  eventName: string,
  properties?: Dict
): RefObject<T> => {
  const { trackLink } = useMixpanel()
  const ref = useRef<T>(null)
  const [refId, setRefId] = useState<string>()
  const prevRefId = usePrevious(refId)

  useEffect(() => {
    if (ref.current) {
      const id = nanoid()
      ref.current.id = id
      setRefId(id)
    }
  }, [ref])

  useEffect(() => {
    if (refId && prevRefId !== refId) {
      trackLink(`#${refId}`, eventName, properties)
    }
  }, [eventName, prevRefId, properties, refId, trackLink])

  return ref
}
