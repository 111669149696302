type AccountIconProps = { className?: string }
export const AccountIcon = ({ className }: AccountIconProps) => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <g id="00-login" transform="translate(-1326.000000, -15.000000)" fillRule="nonzero">
      <g id="01_Header" transform="translate(-23.000000, -13.000000)">
        <g id="Group-14" transform="translate(44.000000, 0.000000)">
          <g id="right" transform="translate(970.000000, 0.157585)">
            <g id="date02" transform="translate(233.000000, 0.000000)">
              <g id="iconfinder_8_3898372" transform="translate(102.000000, 26.842415)">
                <path
                  d="M13.4090909,10.6991145 C15.2147078,9.25029677 15.9090812,6.81951947 15.1412,4.63555894 C14.3733189,2.45159841 12.3104762,0.990259088 9.99545455,0.990259088 C7.68043293,0.990259088 5.61759023,2.45159841 4.84970907,4.63555894 C4.08182792,6.81951947 4.77620129,9.25029677 6.58181818,10.6991145 C2.63264089,12.1355353 0.0028377193,15.8877268 0,20.0900236 L0,20.9991145 L20,20.9991145 L20,20.0900236 C19.9957721,15.8853928 17.3618514,12.1325099 13.4090909,10.6991145 Z M6.36363636,6.45365992 C6.36363636,4.44535174 7.99169182,2.81729628 10,2.81729628 C12.0083082,2.81729628 13.6363636,4.44535174 13.6363636,6.45365992 C13.6363636,8.4619681 12.0083082,10.0900236 10,10.0900236 C7.99169182,10.0900236 6.36363636,8.4619681 6.36363636,6.45365992 Z M1.87272727,19.1809326 C2.33294329,15.037226 5.83536049,11.9022655 10.0045455,11.9022655 C14.1737304,11.9022655 17.6761476,15.037226 18.1363636,19.1809326 L1.87272727,19.1809326 Z"
                  id="Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
