import { useEffect, useCallback, useState } from 'react'
import { defer } from 'rxjs'
import { Map as Mapbox } from 'mapbox-gl'
import { fold, Option, isNone, getOrElse, fromNullable, map as mapSome, chain, isSome } from 'fp-ts/es6/Option'
import { pipe } from 'fp-ts/es6/pipeable'
import { usePortTraffic } from './Traffic/useVesselTraffic'
import { Port, portCenter } from '../Domain/Port'
import { InitializeMap } from './InitializeMap'
import { MapboxProvider } from './Mapbox'
import { fetchPort } from '../Api/Port/fetchPort'
import { DEFAULT_CENTER } from './constants'
import { constant, constNull } from 'fp-ts/es6/function'
import { VesselListing, VESSEL_LISTING_INITIAL_VISIBILITY } from './Traffic/VesselListing/VesselListing'
import { useMapDetailMarkers, MapMarkers } from './Marker/MapMarkers'
import { useFromFetch } from '../lib/useFromFetch'
import { store } from '../dataStore'
import { TrafficAndPortcallsControls } from './Controls/Controls'
import { Legend } from './Controls/Legend'
import { NotificationSubscriptions } from './NotificationSubscriptions'
import { fetchPortVisits } from '../Api/Port/fetchVisits'
import { DestinationPortProvider, useDestinationPortContext } from './DestinationPort'
import { LayersDropdown } from './Controls/LayersDropdown'
import { useMMSIMatcher } from './helpers/paths'
import { fetchVesselStatus } from '../Api/Vessel/fetchStatus'
import { createMMSI } from '../Domain/Vessel'
import { usePrevious } from '../lib/hooks/usePrevious'
import { Pages } from '../constants'
import { useHistory } from 'react-router-dom'
import { useResponsiveness } from '../lib/hooks/useResponsiveness'

type MapWithTrafficAndPortcallsProps = { mapbox: Option<Mapbox> }

export const MapWithTrafficAndPortcalls = ({ mapbox }: MapWithTrafficAndPortcallsProps) => {
  const [center, setCenter] = useState<[number, number]>()
  const { mmsiMatch } = useMMSIMatcher()

  const { data, isLoading } = useFromFetch<Option<Port>>(() =>
    store.metadata
      .fetch()
      .then(({ defaultPort }) => (defaultPort === undefined ? undefined : fetchPort(defaultPort)))
      .then(fromNullable)
  )

  useEffect(() => {
    if (center !== undefined) {
      return
    }

    if (isSome(mmsiMatch)) {
      const subscription = defer(() => fetchVesselStatus(createMMSI(mmsiMatch.value))).subscribe(
        ({
          location: {
            coordinates: [x, y],
          },
        }) => {
          setCenter([x, y])
        }
      )

      return subscription.unsubscribe.bind(subscription)
    } else if (data !== undefined && center === undefined) {
      setCenter(pipe(data, chain(portCenter), getOrElse(constant(DEFAULT_CENTER))))
    }
  }, [data, mmsiMatch, center])

  if (isNone(mapbox) || isLoading || data === undefined) {
    return null
  }

  return (
    <MapboxProvider mapbox={mapbox.value}>
      <DestinationPortProvider portOption={data}>
        <NotificationSubscriptions>
          <WithPort>{port => (center ? <Content port={port} isHandPickedEnabled center={center} /> : null)}</WithPort>
        </NotificationSubscriptions>
      </DestinationPortProvider>
    </MapboxProvider>
  )
}

const WithPort: React.FC<{ children: (port: Port) => JSX.Element | null }> = ({ children }) => {
  const { portOption } = useDestinationPortContext()

  return pipe(portOption, fold(constNull, children))
}

type ContentProps = { port: Port; center: [number, number]; isHandPickedEnabled: boolean }
export function Content({ port, center: initialCenter, isHandPickedEnabled }: ContentProps) {
  const { isMobile } = useResponsiveness()
  const history = useHistory()
  const [center, setCenter] = useState(initialCenter)
  const { detailMarkerData } = useMapDetailMarkers()
  const [isListVisible, setIsListVisible] = useState(VESSEL_LISTING_INITIAL_VISIBILITY)
  const { traffic, portcalls, handPicked, displayState, totalPortVessels } = usePortTraffic(
    port.port,
    useCallback(() => fetchPortVisits(port.port), [port]),
    isHandPickedEnabled
  )

  const prevPort = usePrevious(port.port)

  useEffect(() => {
    if (prevPort !== undefined && prevPort !== port.port) {
      history.push(Pages.MAP)
      pipe(portCenter(port), mapSome(setCenter))
    }
  }, [port, prevPort, history])

  return (
    <>
      <InitializeMap key={`re-render-${center}`} center={center}>
        <MapMarkers
          detailMarkerData={detailMarkerData}
          portcalls={portcalls}
          traffic={traffic}
          handPicked={handPicked}
          displayState={displayState}
          port={port}
        />
        {!isMobile && (
          <>
            <TrafficAndPortcallsControls
              port={{
                center,
                name: port.name,
                port: port.port,
              }}
            />
            <LayersDropdown />
            <Legend name={port.name} withListOffset={isListVisible} />
          </>
        )}
      </InitializeMap>
      <VesselListing
        port={port}
        portcalls={portcalls}
        handPickedVessels={handPicked}
        onListVisibilityChanged={setIsListVisible}
        totalPortVessels={totalPortVessels}
      />
    </>
  )
}
