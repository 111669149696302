import { useCallback } from 'react'
import { useMapboxContext } from '../Mapbox'
import { ControlStripButton, ControlStripEntry } from '../../UI/ControlStrip/ControlStrip'
import MaterialIcon from '../../UI/MaterialIcon'
import styles from './Controls.module.scss'

export type DefaultPortControlProps = {
  center: [number, number]
  name: string
  port: string
}
export function DefaultPortControl({ center, name, port }: DefaultPortControlProps) {
  const { mapbox } = useMapboxContext()

  const handleClick = useCallback(() => mapbox.flyTo({ center }), [center, mapbox])

  return (
    <ControlStripEntry>
      <ControlStripButton onClick={handleClick}>
        <MaterialIcon className={styles.icon} type="gps_fixed" />
        <span>
          {name}&nbsp;({port})
        </span>
      </ControlStripButton>
    </ControlStripEntry>
  )
}
