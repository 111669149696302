import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import classnames from 'classnames'
import { getYear } from 'date-fns'
import { Link } from 'react-router-dom'
import { Pages, PXP_HOMEPAGE_URL } from '../../constants'
import { useDarkMode } from '../../lib/hooks/useDarkMode'
import { useTranslation } from '../../lib/i18n'
import { Toggle } from '../../UI/Toggle/Toggle'
import styles from './MenuNavigation.module.scss'
import { clearMetadataCache } from '../../lib/metadataCacheStorage'
import { useMixpanel } from '../../lib/mixpanel/MixpanelContext'
import { getTokenSilently } from '../../Auth/client'
import jwtDecode from 'jwt-decode'
import { trackError } from '../../lib/trackError'
import MaterialIcon from '../../UI/MaterialIcon'
import { combineLatest } from 'rxjs'
import { store } from '../../dataStore'
import { PILOT_TRACKER_URL, PXP_ADMIN_URL } from '../../config'

function RoleNavigationItems() {
  const AUTH_KEY = 'https://pronto.portcalloptimization.nl/authorization'
  const [roles, setRoles] = useState<string[]>([])

  useEffect(() => {
    const sub = combineLatest(store.metadata.fetch(), getTokenSilently).subscribe(([meta, token]) => {
      const roles = ['USHOU', 'USCRP'].includes(meta.defaultPort ?? '') ? ['PilotTracker'] : []

      try {
        const jwt: Partial<{ [AUTH_KEY]: { roles: string[] } }> = jwtDecode(token)

        setRoles(roles.concat(jwt[AUTH_KEY]?.roles ?? []))
      } catch (e) {
        if (e instanceof Error) {
          trackError(e)
        }
      }
    })

    return sub.unsubscribe.bind(sub)
  }, [])

  return (
    <>
      {roles.includes('PilotTracker') && (
        <li className={styles.entry}>
          <a href={PILOT_TRACKER_URL} rel="noreferrer" target="_blank" className={styles.inner}>
            <label className={styles.text}>
              <span>Pilot Tracker</span>
              <MaterialIcon type="chevron_right" />
            </label>
          </a>
        </li>
      )}
      {['Administrator', 'User Super Admin', 'User Admin'].some(role => roles.includes(role)) && (
        <li className={styles.entry}>
          <a href={PXP_ADMIN_URL} rel="noreferrer" target="_blank" className={styles.inner}>
            <label className={styles.text}>
              <span>User Admin</span>
              <MaterialIcon type="chevron_right" />
            </label>
          </a>
        </li>
      )}
    </>
  )
}

type MenuNavigationProps = { isOpen: boolean; onClose: () => void }
export const MenuNavigation: React.FC<MenuNavigationProps> = ({ isOpen, onClose }) => {
  const { logout } = useAuth0()
  const { reset } = useMixpanel()
  const { t } = useTranslation()
  const { isDarkMode, setDarkMode } = useDarkMode()

  const currentYear = useMemo(() => getYear(new Date()), [])

  const onLogout = useCallback(() => {
    reset()
    clearMetadataCache()

    logout({
      returnTo: window.location.origin,
    })
  }, [logout, reset])

  return (
    <>
      {isOpen && <div className={styles.menuNavigationOverlay} onClick={onClose}></div>}
      <nav className={classnames(styles.menuNavigation, { [styles.menuNavigationOpen]: isOpen })}>
        <ul className={classnames(styles.section, styles.sectionTop)}>
          <li className={styles.entry}>
            <Link onClick={onClose} to={Pages.MAP} className={styles.inner}>
              <label className={styles.text}>{t('ShipTracker.Menu.ShowMap')}</label>
            </Link>
          </li>
          <li className={styles.entry}>
            <Link onClick={onClose} to={Pages.SUPPORT} className={styles.inner}>
              <span className={styles.text}>{t('ShipTracker.Menu.Support')}</span>
            </Link>
          </li>
          <RoleNavigationItems />
        </ul>
        <ul className={classnames(styles.section, styles.sectionBottom)}>
          <li className={classnames(styles.entry, styles.inner)}>
            <label className={styles.text}>{t('ShipTracker.Menu.DarkMode')}</label>
            <Toggle isChecked={isDarkMode} handleToggle={setDarkMode} />
          </li>
          <li className={styles.entry}>
            <Link to={Pages.LEGAL_TERMS} className={styles.inner}>
              <span className={styles.text}>{t('ShipTracker.Menu.LegalTerms')}</span>
            </Link>
          </li>
          <li className={styles.entry}>
            <button className={styles.inner} onClick={() => onLogout()}>
              <span className={styles.text}>{t('ShipTracker.Account.Logout')}</span>
            </button>
          </li>
          <li className={classnames(styles.entry, styles.entrySmall, styles.entryInactive, styles.inner)}>
            <span className={styles.text}>
              &copy; {currentYear} Shiptracker by{' '}
              <a href={PXP_HOMEPAGE_URL} target="_blank" rel="noopener noreferrer">
                PortXchange
              </a>
            </span>
          </li>
        </ul>
      </nav>
    </>
  )
}
