import { Auth0Provider } from '@auth0/auth0-react'
import ReactDOM from 'react-dom'
import {
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  MIXPANEL_API_HOST,
  MIXPANEL_ID,
  isTestEnvironment,
  AUTH0_AUDIENCE,
} from './config'
import { ShipTrackerRouter } from './Router'
import './styles/base.module.scss'
import './styles/theme.module.scss'
import 'overlayscrollbars/css/OverlayScrollbars.css'
import { ResponsivenessProvider } from './lib/hooks/useResponsiveness'
import { PortalProvider } from './lib/hooks/usePortal'
import { ToastContainer } from 'react-toastify'
import { WithAuth0Loaded } from './Auth/WithAuth0Loaded'
import { MixpanelProvider } from './lib/mixpanel/MixpanelContext'

ReactDOM.render(
  <>
    <MixpanelProvider
      mixpanelId={MIXPANEL_ID}
      config={{
        debug: isTestEnvironment,
        api_host: MIXPANEL_API_HOST,
        cross_subdomain_cookie: !isTestEnvironment,
      }}
    >
      <Auth0Provider
        domain={AUTH0_DOMAIN}
        clientId={AUTH0_CLIENT_ID}
        audience={AUTH0_AUDIENCE}
        redirectUri={window.location.origin}
        useRefreshTokens
      >
        <WithAuth0Loaded>
          <ResponsivenessProvider>
            <PortalProvider id="portals">
              <ShipTrackerRouter />
            </PortalProvider>
          </ResponsivenessProvider>
        </WithAuth0Loaded>
      </Auth0Provider>
      <ToastContainer position="bottom-center" />
    </MixpanelProvider>
  </>,
  document.getElementById('root')
)
