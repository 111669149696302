import React, { useCallback } from 'react'

import { format } from 'date-fns'

import { fetchMeasurementUnit, SystemOfMeasurement } from '../Domain/User'
import {
  addUnit,
  cbmToBbls,
  cbmToCuft,
  convertToUnit,
  createMeasurementSystemConverter,
  metersToFeet,
} from '../lib/convert-units'

import styles from './VesselDetailsOverlay.module.scss'
import { useFromFetch } from '../lib/useFromFetch'
import { fetchVesselDetails } from '../Api/Vessel/fetchDetails'
import { FullScreenOverlay } from '../UI/FullScreenOverlay/FullScreenOverlay'
import { RenderQueryResult } from '../lib/RenderQueryResult'
import { findCountryByAlpha2 } from '../Domain/Countries'
import { pipe } from 'fp-ts/lib/function'
import { fold } from 'fp-ts/es6/Option'
import { constNull } from 'fp-ts/es6/function'
import { InlineLoader } from '../UI/Loader/InlineLoader'
import { VesselMasterData } from '../Domain/Vessel'
import { useHistory } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { selectedVesselPath } from '../Map/helpers/paths'
import { ImagePlaceHolder } from '../UI/ImagePlaceholder/ImagePlaceHolder'

type VesselDetailsOverlayProps = Readonly<{
  mmsi: string
}>

type DataAccessor<Data, Value> = {
  label: string
  accessor: (data: Data) => Value
  formatter: (value: any, measurementSystem: SystemOfMeasurement) => React.ReactNode
  isPaidOnly: boolean
}

const createAccessorFactory = <Data extends {}>() => ({
  createDataAccessor: <Value extends unknown>(
    label: string,
    accessor: (data: Data) => Value,
    formatter: (value: any, measurementSystem: SystemOfMeasurement) => React.ReactNode,
    isPaidOnly?: boolean
  ): DataAccessor<Data, Value> => ({
    label,
    accessor,
    formatter,
    isPaidOnly: !!isPaidOnly,
  }),
})

const { createDataAccessor } = createAccessorFactory<VesselMasterData>()

const identity = <A extends unknown>(input: A): A => input

const valueOrAlternative = <Value extends unknown>(
  alternative: React.ReactNode,
  formatter: (value: Value, measurementSystem: SystemOfMeasurement) => React.ReactNode
) => (input: Value, measurementSystem: SystemOfMeasurement): React.ReactNode =>
  input !== undefined ? formatter(input, measurementSystem) : alternative

const generalInformationAccessors = [
  createDataAccessor('Vessel Type', ({ spireVesselType }) => spireVesselType, valueOrAlternative('N/A', identity)),
  createDataAccessor('Sub Type', ({ spireSubtype }) => spireSubtype, valueOrAlternative('N/A', identity)),
  createDataAccessor('IMO', ({ imo }) => imo, valueOrAlternative('N/A', identity)),
  createDataAccessor('MMSI', ({ mmsi }) => mmsi, valueOrAlternative('N/A', identity)),
  createDataAccessor('Call Sign', ({ callSign }) => callSign, valueOrAlternative('N/A', identity)),
  createDataAccessor(
    'Flag',
    ({ flag }) =>
      flag &&
      pipe(
        findCountryByAlpha2(flag),
        fold(constNull, ({ name }) => name)
      ),
    valueOrAlternative('N/A', identity)
  ),
  createDataAccessor(
    'Vessel Name Date',
    ({ vesselNameDate }) => vesselNameDate && format(vesselNameDate, 'MM/dd/yyyy'),
    valueOrAlternative('N/A', identity),
    true
  ),
  createDataAccessor('Built Year', ({ builtYear }) => builtYear, valueOrAlternative('N/A', identity)),
  createDataAccessor('Builder', ({ builder }) => builder, valueOrAlternative('N/A', identity), true),
  createDataAccessor('Class Society', ({ classSociety }) => classSociety, valueOrAlternative('N/A', identity), true),
  createDataAccessor('Ice Class', ({ iceClass }) => iceClass, valueOrAlternative('N/A', identity), true),
  createDataAccessor('Owner', ({ commercialOwner }) => commercialOwner, valueOrAlternative('N/A', identity), true),
]
const vesselParticularsAccessors = [
  createDataAccessor('Summer DWT', ({ dwt }) => dwt, valueOrAlternative('N/A', addUnit('MT')), true),
  createDataAccessor(
    'Gross Tonnage',
    ({ grossTonnage }) => grossTonnage,
    valueOrAlternative('N/A', addUnit('MT')),
    true
  ),
  createDataAccessor('Net Tonnage', ({ netTonnage }) => netTonnage, valueOrAlternative('N/A', addUnit('MT')), true),
  createDataAccessor(
    'Length Overall',
    ({ lengthOverall }) => lengthOverall,
    valueOrAlternative(
      'N/A',
      createMeasurementSystemConverter({
        [SystemOfMeasurement.Metric]: addUnit('m'),
        [SystemOfMeasurement.Imperial]: convertToUnit(metersToFeet, 'ft'),
      })
    ),
    true
  ),
  createDataAccessor(
    'Extreme Breadth',
    ({ beam }) => beam,
    valueOrAlternative(
      'N/A',
      createMeasurementSystemConverter({
        [SystemOfMeasurement.Metric]: addUnit('m'),
        [SystemOfMeasurement.Imperial]: convertToUnit(metersToFeet, 'ft'),
      })
    ),
    true
  ),
  createDataAccessor(
    'Depth',
    ({ depth }) => depth,
    valueOrAlternative(
      'N/A',
      createMeasurementSystemConverter({
        [SystemOfMeasurement.Metric]: addUnit('m'),
        [SystemOfMeasurement.Imperial]: convertToUnit(metersToFeet, 'ft'),
      })
    ),
    true
  ),
  createDataAccessor(
    'Summer Draft',
    ({ maxDraughtDerived }) => maxDraughtDerived,
    valueOrAlternative(
      'N/A',
      createMeasurementSystemConverter({
        [SystemOfMeasurement.Metric]: addUnit('m'),
        [SystemOfMeasurement.Imperial]: convertToUnit(metersToFeet, 'ft'),
      })
    ),
    true
  ),
  createDataAccessor(
    'Max Air Draft',
    ({ airDraught }) => airDraught,
    valueOrAlternative(
      'N/A',
      createMeasurementSystemConverter({
        [SystemOfMeasurement.Metric]: addUnit('m'),
        [SystemOfMeasurement.Imperial]: convertToUnit(metersToFeet, 'ft'),
      })
    ),
    true
  ),
  createDataAccessor('TPC', ({ tpc }) => tpc, valueOrAlternative('N/A', addUnit('MT')), true),
  createDataAccessor(
    'Displacement',
    ({ displacement }) => displacement,
    valueOrAlternative('N/A', addUnit('MT')),
    true
  ),
  createDataAccessor(
    'Liquid Capacity 98%',
    ({ liquidCapacity98Pcnt }) => liquidCapacity98Pcnt,
    valueOrAlternative(
      'N/A',
      createMeasurementSystemConverter({
        [SystemOfMeasurement.Metric]: addUnit(
          <>
            m<sup>3</sup>
          </>
        ),
        [SystemOfMeasurement.Imperial]: convertToUnit(cbmToBbls, 'BBLS'),
      })
    ),
    true
  ),
  createDataAccessor(
    'Grain Capacity',
    ({ grainCapacity }) => grainCapacity,
    valueOrAlternative(
      'N/A',
      createMeasurementSystemConverter({
        [SystemOfMeasurement.Metric]: addUnit(
          <>
            m<sup>3</sup>
          </>
        ),
        [SystemOfMeasurement.Imperial]: convertToUnit(cbmToCuft, 'cu ft'),
      })
    ),
    true
  ),
  createDataAccessor(
    'Container Intake',
    ({ capacityContainers }) => capacityContainers,
    valueOrAlternative('N/A', addUnit('TEU')),
    true
  ),
  createDataAccessor(
    'Main Engine Designer',
    ({ mainEngineDesigner }) => mainEngineDesigner,
    valueOrAlternative('N/A', identity),
    true
  ),
  createDataAccessor('Main Engine Model', ({ mainEngine }) => mainEngine, valueOrAlternative('N/A', identity), true),
  createDataAccessor(
    'Main Engine Capacity',
    ({ engineCapacity }) => engineCapacity,
    valueOrAlternative('N/A', addUnit('kW')),
    true
  ),
]

export const VesselDetailsOverlay = ({ mmsi }: VesselDetailsOverlayProps) => {
  const history = useHistory()
  const { isAuthenticated } = useAuth0()
  const query = useFromFetch(
    useCallback(() => Promise.all([fetchVesselDetails(`MMSI${mmsi}`), fetchMeasurementUnit(isAuthenticated)]), [
      isAuthenticated,
      mmsi,
    ])
  )

  return (
    <FullScreenOverlay title="Vessel Details" onClose={() => history.push(selectedVesselPath(mmsi))}>
      <div className={styles.fullVesselDetails}>
        <RenderQueryResult
          query={query}
          LoadingComponent={<InlineLoader />}
          ErrorComponent={<div>Something went wrong, please try again later.</div>}
        >
          {([vesselDetails, measurementUnit]) => (
            <>
              <div className={styles.imageContainer}>
                {vesselDetails.photoUrl ? (
                  <img src={vesselDetails.photoUrl} alt={vesselDetails.name} className={styles.image} />
                ) : (
                  <ImagePlaceHolder className={styles.placeholder} />
                )}
              </div>
              <div className={styles.detailsListContainer}>
                <h3 className={styles.shipName}>{vesselDetails.name}</h3>
                <h4 className={styles.groupHeading}>General information</h4>
                <dl className={styles.detailsList}>
                  {generalInformationAccessors.map((row, arr, index) => (
                    <div className={styles.group} key={`${row.label}-${index}`}>
                      <dt className={styles.defLabel}>{row.label}</dt>
                      <dd className={styles.defValue}>{row.formatter(row.accessor(vesselDetails), measurementUnit)}</dd>
                    </div>
                  ))}
                </dl>
                <h4 className={styles.groupHeading}>Vessel Particulars</h4>
                <dl className={styles.detailsList}>
                  {vesselParticularsAccessors.map((row, arr, index) => (
                    <div className={styles.group} key={`${row.label}-${index}`}>
                      <dt className={styles.defLabel}>{row.label}</dt>
                      <dd className={styles.defValue}>{row.formatter(row.accessor(vesselDetails), measurementUnit)}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </>
          )}
        </RenderQueryResult>
      </div>
    </FullScreenOverlay>
  )
}
