import { createMap } from './Map/Mapbox'
import Header from './Header/Header'
import styles from './ShipTracker.module.scss'
import { useMapbox } from './Map/useMapbox'
import { Map as Mapbox } from 'mapbox-gl'
import { MapWithTrafficAndPortcalls } from './Map/MapWithTrafficAndPortcalls'
import { useAuth0 } from '@auth0/auth0-react'
import { matchPath, useHistory, useLocation } from 'react-router-dom'
import { pipe, constUndefined } from 'fp-ts/es6/function'
import { fold, Option, fromNullable } from 'fp-ts/es6/Option'
import classnames from 'classnames'
import { useDarkMode } from './lib/hooks/useDarkMode'
import { getShowCasePort, Pages, SHIP_TRACKER_THEME_CSS, SHIP_TRACKER_THEME_DARK_CSS } from './constants'
import { PortalProvider } from './lib/hooks/usePortal'
import CookieBar from './UI/CookieBar/CookieBar'
import { useCookieSettings } from './UI/CookieBar/useCookieSettings'
import { ShowCase, AuthenticatedShowCase } from './Map/ShowCaseMap'
import { Port } from './Domain/Port'
import { VesselSearchResult } from './Domain/VesselSearchResult'
import { VesselDetailsOverlay } from './VesselDetailsOverlay/VesselDetailsOverlay'
import { isShowCase } from './config'
import { selectedVesselPath } from './Map/helpers/paths'
import { setSearched } from './Map/Traffic/vesselSearch'
import { Modal } from './UI/Modal/Modal'
import { useEffect, useState } from 'react'
import { getTokenSilently } from './Auth/client'
import jwtDecode from 'jwt-decode'
import { store } from './dataStore'
import { combineLatest, from } from 'rxjs'

enum MapKind {
  TRAFFIC,
  TRAFFIC_AND_PORTCALLS,
  SHOW_CASE,
}

type MapType =
  | { kind: MapKind.TRAFFIC_AND_PORTCALLS }
  | { kind: MapKind.SHOW_CASE; port: Port; isAuthenticated: boolean }

type ShipTrackerMapProps = {
  mapbox: Option<Mapbox>
  mapType: MapType
  selectedVessel: Option<string>
}

type TrafficMapProps = Omit<ShipTrackerMapProps, 'selectedVessel'>

function TrafficMap({ mapbox, mapType }: TrafficMapProps) {
  switch (mapType.kind) {
    case MapKind.TRAFFIC_AND_PORTCALLS: {
      return <MapWithTrafficAndPortcalls mapbox={mapbox} />
    }

    case MapKind.SHOW_CASE: {
      return mapType.isAuthenticated ? (
        <AuthenticatedShowCase port={mapType.port} mapbox={mapbox} />
      ) : (
        <ShowCase port={mapType.port} mapbox={mapbox} />
      )
    }
  }
}

function NoAccessModal() {
  const [hasAccess, setHasAccess] = useState<boolean>()

  useEffect(() => {
    const AUTH_KEY = 'https://pronto.portcalloptimization.nl/authorization'

    const sub = combineLatest(from(store.metadata.fetch()), getTokenSilently).subscribe(([meta, token]) => {
      try {
        const jwt: Partial<{ [AUTH_KEY]: { roles: string[] } }> = jwtDecode(token)

        setHasAccess((jwt[AUTH_KEY]?.roles.includes('ShipTracker') ?? false) && meta.defaultPort !== undefined)
      } catch {
        setHasAccess(false)
      }
    })

    return sub.unsubscribe.bind(sub)
  }, [])

  return hasAccess === false ? (
    <Modal isVisible onClose={() => {}}>
      <div className={styles.modal}>
        <h3>Access Denied</h3>
        <p>At this moment you dont have acces to Shiptracker</p>
      </div>
    </Modal>
  ) : null
}

const ShipTrackerMap = ({ mapbox, mapType, selectedVessel }: ShipTrackerMapProps) => {
  if (isShowCase) {
    // Disable VesselDetailsOverlay for SHOW_CASE envs
    return <TrafficMap mapbox={mapbox} mapType={mapType} />
  }

  return (
    <>
      <NoAccessModal />
      {pipe(
        selectedVessel,
        fold(
          () => <TrafficMap mapbox={mapbox} mapType={mapType} />,
          mmsi => <VesselDetailsOverlay mmsi={mmsi} />
        )
      )}
    </>
  )
}

const findMatch = (pathname: string) =>
  pipe(
    matchPath<{ mmsi?: string }>(pathname, { path: `${Pages.MAP}/vessels/:mmsi/details` })?.params.mmsi,
    fromNullable
  )

export default function Main() {
  const { isAuthenticated } = useAuth0()
  const { onMount, mapbox } = useMapbox(createMap)
  const { isDarkMode } = useDarkMode()
  const { areCookiesEnabled } = useCookieSettings()
  const { pathname } = useLocation()
  const selectedVessel = findMatch(pathname)
  const history = useHistory()

  return (
    <main className={classnames(styles.main, SHIP_TRACKER_THEME_CSS, { [SHIP_TRACKER_THEME_DARK_CSS]: isDarkMode })}>
      <PortalProvider id="shiptracker-portal">
        <Header
          handleSearchResult={pipe(
            selectedVessel,
            fold(
              () => ({ ship: { mmsi } }: VesselSearchResult, query: string) => {
                if (mmsi) {
                  setSearched({ mmsi, query })
                  history.push(selectedVesselPath(mmsi))
                }
              },
              constUndefined
            )
          )}
        />
        <div className={styles.map}>
          <div ref={onMount} className={styles.mapBox} />
          {pipe(
            getShowCasePort(),
            fromNullable,
            fold(
              () => (
                <>
                  <ShipTrackerMap
                    selectedVessel={selectedVessel}
                    mapType={{ kind: MapKind.TRAFFIC_AND_PORTCALLS }}
                    mapbox={mapbox}
                  />
                  {!areCookiesEnabled && <CookieBar />}
                </>
              ),
              port => (
                <>
                  <ShipTrackerMap
                    selectedVessel={selectedVessel}
                    mapType={{ kind: MapKind.SHOW_CASE, port, isAuthenticated }}
                    mapbox={mapbox}
                  />
                  {!areCookiesEnabled && <CookieBar />}
                </>
              )
            )
          )}
        </div>
      </PortalProvider>
    </main>
  )
}
