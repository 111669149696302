import { Point } from 'geojson'
import { Option, none, chain, fromNullable } from 'fp-ts/es6/Option'
import { Brand } from '../lib/brand'
import { Visit } from './PortCall'
import { IconFeature, ShapeFeature, iconFeatureFromJson } from './VesselFeature'
import { vesselShapePolygon } from '../Map/helpers/vesselShapPolygon'
import { ShipTrackerEvent } from './PortCall'
import { pipe } from 'fp-ts/es6/function'
import { findFirst } from 'fp-ts/es6/Array'
import midsJson from 'mids/mids.json'
import { Country, findCountryByAlpha3 } from './Countries'
import { UUID } from './UUID'
import { differenceInHours, parseISO, parseJSON } from 'date-fns'

type MIDS = { [key: string]: typeof midsJson['201'] }
const mids: MIDS = { ...midsJson }

export enum VesselSource {
  AIS = 'AIS',
  PORT_VESSEL = 'PORT_VESSEL',
  HAND_PICKED = 'HAND_PICKED',
}

export type MMSI = Brand<string, 'MMSI'>
export type IMO = Brand<string, 'IMO'>
export type ENI = Brand<string, 'ENI'>
export type USCG = Brand<string, 'USCG'>

export const emptyMMSIs: MMSI[] = []

export const createMMSI = (val: string | number): MMSI => String(val) as MMSI

enum ArrivalTimeKind {
  PREDICTED,
  REPORTED,
  ACTUAL,
}

type ReportedArrivalTime = {
  kind: ArrivalTimeKind.REPORTED
  value?: string
  offset?: number
}

type PredictedArrivalTime = {
  kind: ArrivalTimeKind.PREDICTED
  value: string
  offset?: number
  labelKey: string
}

type ActualArrivalTime = {
  kind: ArrivalTimeKind.ACTUAL
  value: string
  offset?: number
  labelKey: string
}

export type VesselCommonProperties = Readonly<{
  mmsi: number
  shipName?: string
  destination?: string
  destinationPort?: string
  speedOverGround?: number
  draught?: number
  reportedArrivalTime?: ReportedArrivalTime
  predictedArrivalTime?: PredictedArrivalTime
  actualArrivalTime?: ActualArrivalTime
  status: string
}>

export type VesselJson = Omit<VesselCommonProperties, 'shipTypeCategory' | 'status' | 'etaLocal'> & {
  eta?: string
  etaLocal?: string
  status?: string
  location: Point
  courseOverGround?: number
  heading: number
  shipDimensions: {
    distanceToBow: number
    distanceToStern: number
    distanceToPort: number
    distanceToStarboard: number
  }
}

export type PortVesselProperties = VesselCommonProperties & {
  source: VesselSource.PORT_VESSEL
  portcallId?: Visit['eventPortcallId']
  arrivalTime?: ShipTrackerEvent
  country: Option<Country>
  spireVesselType: string
}

export type VesselProperties = VesselCommonProperties & { source: VesselSource.AIS }
export type HandPickedVesselProperties = VesselCommonProperties &
  Partial<Pick<Visit, 'port' | 'eventPortcallId'>> & {
    arrivalTime: Visit['arrivalTime']
    source: VesselSource.HAND_PICKED
  }

export type AllVesselProperties = VesselProperties | HandPickedVesselProperties | PortVesselProperties

type VesselFeatures = Readonly<{
  icon: IconFeature
  shape: ShapeFeature
}>

export type PortVessel = Readonly<{
  properties: PortVesselProperties
  features: VesselFeatures
}>

export type TrafficVessel = Readonly<{
  properties: VesselProperties
  features: VesselFeatures
}>

export type HandPickedVessel = Readonly<{ properties: HandPickedVesselProperties; features: VesselFeatures }>

export type Vessel = PortVessel | HandPickedVessel | TrafficVessel

export const emptyTrafficVessels: TrafficVessel[] = []

// These properties come from https://github.com/portxchange/pronto-domain/blob/master/domain/src/main/scala/pronto/domain/masterdata/VesselMasterData.scala#L104-L150
export type VesselMasterDataJson = Readonly<{
  id: UUID
  imo?: IMO
  mmsi?: MMSI
  eni?: ENI
  uscg?: USCG
  name?: string
  callSign?: string
  flag?: string
  shipType?: string
  statCodeDerived?: string
  maxDraughtDerived?: number
  lengthOverall?: number
  beam?: number
  airDraught?: number
  aisPositionAllowed: boolean
  aisPositionAllowedReason?: string
  aisPositionSensitive?: boolean
  outOfService: boolean
  invalid: boolean
  isDeepSeaVessel?: boolean
  commercialOwner?: string
  spireVesselType?: string
  spireSubtype?: string
  iceClass?: string
  photoUrl?: string
  vesselNameDate?: string
  builtYear?: number
  builder?: string
  classSociety?: string
  dwt?: number
  grossTonnage?: number
  netTonnage?: number
  tpc?: number
  depth?: number
  displacement?: number
  liquidCapacity98Pcnt?: number
  grainCapacity?: number
  mainEngineDesigner?: string
  mainEngine?: string
  engineCapacity?: number
  capacityContainers?: number
}>

export type VesselMasterData = Omit<VesselMasterDataJson, 'vesselNameDate'> & {
  vesselNameDate?: Date
}

export type VesselMasterDataWithPictureUrl = VesselMasterData & { pictureUrl: Option<string> }

export const trafficVesselFromJson = (json: VesselJson): TrafficVessel => ({
  properties: { ...vesselPropertiesFromJson(json), source: VesselSource.AIS },
  features: {
    icon: iconFeatureFromJson(json, VesselSource.AIS),
    shape: vesselShapePolygon(json, VesselSource.AIS),
  },
})

export const vesselPropertiesFromJson = ({
  shipName,
  mmsi,
  destination,
  destinationPort,
  speedOverGround,
  draught,
  eta,
  etaLocal,
  status,
}: VesselJson): VesselCommonProperties => ({
  mmsi,
  shipName,
  destination,
  destinationPort,
  draught,
  speedOverGround,
  reportedArrivalTime: reportedArrivalTime(eta, etaLocal),
  status: status === undefined || status === 'Undefined' ? 'Unknown' : status,
})

export const portcallFromVesselVisit = (visit: Visit | undefined, json: VesselJson): PortVessel => ({
  properties: {
    ...vesselPropertiesFromJson(json),
    portcallId: visit?.eventPortcallId,
    source: VesselSource.PORT_VESSEL,
    arrivalTime: visit?.arrivalTime,
    predictedArrivalTime: visit?.arrivalTime && predictedArrivalTime(visit.arrivalTime),
    actualArrivalTime: visit?.arrivalTime && actualArrivalTime(visit?.arrivalTime),
    country: visit?.country ?? none,
    spireVesselType: visit?.spireVesselType ?? 'Barges & Others',
  },
  features: {
    icon: iconFeatureFromJson(json, VesselSource.PORT_VESSEL, visit?.eventPortcallId),
    shape: vesselShapePolygon(json, VesselSource.PORT_VESSEL, visit?.eventPortcallId),
  },
})

export const handPickedFromVesselVisit = (visit: Visit | undefined, json: VesselJson): HandPickedVessel => ({
  properties: {
    ...vesselPropertiesFromJson(json),
    source: VesselSource.HAND_PICKED,
    port: visit?.port,
    eventPortcallId: visit?.eventPortcallId,
    arrivalTime: visit?.arrivalTime,
  },

  features: {
    icon: iconFeatureFromJson(json, VesselSource.HAND_PICKED),
    shape: vesselShapePolygon(json, VesselSource.HAND_PICKED),
  },
})

const ATAEvents = ['pilotBoardingPlace.ata.ais', 'port.ata.ais', 'anchorArea.ata.ais'] as const
const ETAEvents = [
  'port.eta.ais',
  'pilotBoardingPlace.eta.ais',
  'pilotBoardingPlace.pta.portAuthority',
  'berth.eta.portAuthority',
] as const

type ATAEvent = typeof ATAEvents[number]
type ETAEvent = typeof ETAEvents[number]
const ATAEventsLookup: string[] = ATAEvents.slice()
const ETAEventsLookup: string[] = ETAEvents.slice()

const ataLabels: { [key in ATAEvent]: string } = {
  'pilotBoardingPlace.ata.ais': 'ATA_PBP',
  'port.ata.ais': 'ATA_PORT',
  'anchorArea.ata.ais': 'ATA_ANCHOR_AREA',
}

const etaLabels: { [key in ETAEvent]: string } = {
  'port.eta.ais': 'ETA_PBP',
  'pilotBoardingPlace.eta.ais': 'ETA_PBP',
  'pilotBoardingPlace.pta.portAuthority': 'ETA_PBP',
  'berth.eta.portAuthority': 'ETA_BERTH',
}

const isATAEvent = (e: string): e is ATAEvent => ATAEventsLookup.includes(e)
const isETAEvent = (e: string): e is ETAEvent => ETAEventsLookup.includes(e)

const actualArrivalTime = ({ eventTime, eventType, eventTimeLocal }: ShipTrackerEvent): ActualArrivalTime | undefined =>
  isATAEvent(eventType) && eventTime
    ? {
        kind: ArrivalTimeKind.ACTUAL,
        value: eventTime,
        offset: eventTimeLocal ? differenceInHours(parseJSON(eventTimeLocal), parseJSON(eventTime)) : undefined,
        labelKey: `ShipTracker.Common.${ataLabels[eventType]}`,
      }
    : undefined

const reportedArrivalTime = (eta: string | undefined, etaLocal: string | undefined): ReportedArrivalTime => ({
  kind: ArrivalTimeKind.REPORTED,
  value: etaLocal ? etaLocal : eta,
  offset: eta && etaLocal ? differenceInHours(parseJSON(etaLocal), parseJSON(eta)) : undefined,
})

const predictedArrivalTime = ({
  eventTime,
  eventType,
  eventTimeLocal,
}: ShipTrackerEvent): PredictedArrivalTime | undefined =>
  isETAEvent(eventType)
    ? {
        kind: ArrivalTimeKind.PREDICTED,
        value: eventTime,
        offset: eventTimeLocal ? differenceInHours(parseJSON(eventTimeLocal), parseJSON(eventTime)) : undefined,
        labelKey: `ShipTracker.Common.${etaLabels[eventType]}`,
      }
    : undefined

export const arrivalTimeLabelKey = (
  arrivalTime: ReportedArrivalTime | PredictedArrivalTime | ActualArrivalTime | undefined
) =>
  arrivalTime === undefined || arrivalTime.kind === ArrivalTimeKind.REPORTED
    ? 'ShipTracker.Common.ETA'
    : arrivalTime.labelKey

export const arrivalTimeKindLabelKey = (kind: ArrivalTimeKind | undefined) =>
  kind === ArrivalTimeKind.PREDICTED ? `ShipTracker.Common.Predicted` : 'ShipTracker.Common.Reported'

export const isActualArrivalTime = (
  arrivalTime: ActualArrivalTime | PredictedArrivalTime | ReportedArrivalTime | undefined
): arrivalTime is ActualArrivalTime => arrivalTime !== undefined && arrivalTime.kind === ArrivalTimeKind.ACTUAL

export const findByMMSI = <A extends Vessel>(mmsi: MMSI) =>
  findFirst<A>(({ properties }) => createMMSI(properties.mmsi) === mmsi)

export const vesselDestinationFromProperties = (
  properties: VesselProperties | PortVesselProperties | HandPickedVesselProperties
) => pipe('port' in properties ? properties.port : properties.destinationPort ?? properties.destination, fromNullable)

export const getBestArrivalTime = ({
  actualArrivalTime,
  predictedArrivalTime,
  reportedArrivalTime,
}: Pick<VesselCommonProperties, 'reportedArrivalTime' | 'predictedArrivalTime' | 'actualArrivalTime'>) =>
  actualArrivalTime ? actualArrivalTime : predictedArrivalTime ? predictedArrivalTime : reportedArrivalTime

export const vesselOriginByMMSI = (mmsi: MMSI) => pipe(mids[mmsi.substr(0, 3)], fromNullable)

export const countryByMMSI = (mmsi: number | string) =>
  pipe(
    mmsi,
    createMMSI,
    vesselOriginByMMSI,
    chain(([, alpha3]) => findCountryByAlpha3(alpha3))
  )

export const parseVesselDetails = ({ vesselNameDate, ...details }: VesselMasterDataJson): VesselMasterData => ({
  ...details,
  vesselNameDate: vesselNameDate ? parseISO(vesselNameDate) : undefined,
})

export const showUTCOffset = (offset: number | undefined) =>
  offset === undefined ? '' : `(UTC${offset > 0 ? '+' : ''}${offset})`

export const formatArrivalTime = (dateTime: string, offset: number | undefined) =>
  `${Intl.DateTimeFormat('default', {
    timeZone: 'UTC',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }).format(new Date(dateTime))} LT ${showUTCOffset(offset)}`
