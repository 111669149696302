import { Unlocode } from '../../Domain/Port'
import { Visit, VisitJson, visitFromJson } from '../../Domain/PortCall'
import { BACKEND_URL } from '../../config'
import { authRequest } from '../auth'

const cache: Record<Unlocode, Visit[]> = {}

export const fetchPortVisits = (port: Unlocode) =>
  authRequest
    .get(`${BACKEND_URL}/api/v2/visits?port=${port}`)
    .json<VisitJson[]>()
    .then((res): Visit[] => res.map(visitFromJson))
    .then(visits => {
      // store visits for lookup in details
      cache[port] = visits

      return visits
    })

export const fetchStoredVisits = (port: Unlocode) =>
  port in cache ? Promise.resolve(cache[port]) : fetchPortVisits(port)
