import { useEffect } from 'react'
import './lib/i18n'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import ShipTracker from './ShipTracker'
import { Pages } from './constants'
import { useAuth0 } from '@auth0/auth0-react'
import { initializeClient } from './Auth/client'
import { useHandleAuthError } from './Auth/useHandleAuthError'
import { useHandleEmailVerificationSuccess } from './Auth/useHandleEmailVerificationSuccess'
import { useTracking } from './lib/hooks/useTracking'
import { LegalTermsPage } from './Generic/LegalTermsPage'
import { SupportPage } from './Generic/SupportPage'
import { isShowCase } from './config'
import { LegacyRedirectSupport } from './LegacyRedirectSupport'
import { LoginPage } from './Account/LoginPage/LoginPage'
import { useCustomStyleSheet } from './lib/hooks/useCustomStylesheet'

const NotFound = () => <Route>404: Page not found</Route>

const RoutesWithTracking = ({ user }: { user?: { email?: string } }) => {
  useHandleEmailVerificationSuccess()
  useTracking(user?.email)

  return (
    <Switch>
      <Route exact path={Pages.MAIN}>
        {isShowCase ? (
          <LegacyRedirectSupport>
            <Redirect to={Pages.MAP} />
          </LegacyRedirectSupport>
        ) : (
          <Redirect to={Pages.MAP} />
        )}
      </Route>
      <Route path={Pages.MAP}>
        <ShipTracker />
      </Route>
      <Route exact path={Pages.SUPPORT}>
        <SupportPage />
      </Route>
      <Route exact path={Pages.LEGAL_TERMS}>
        <LegalTermsPage />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  )
}

function LoginRedirect() {
  const { loginWithRedirect } = useAuth0()
  useEffect(() => {
    loginWithRedirect({ max_age: 0 })
  }, [loginWithRedirect])

  return null
}

export const ShipTrackerRouter = () => {
  const { isAuthenticated, getAccessTokenSilently, error, user } = useAuth0()

  useHandleAuthError(error)

  useCustomStyleSheet(isAuthenticated)

  useEffect(() => {
    initializeClient({ isAuthenticated, getTokenSilently: getAccessTokenSilently })
  }, [isAuthenticated, getAccessTokenSilently])

  if (isShowCase) {
    return (
      <BrowserRouter>
        <RoutesWithTracking user={user} />
      </BrowserRouter>
    )
  }

  if (isAuthenticated) {
    return (
      <BrowserRouter>
        <RoutesWithTracking user={user} />
      </BrowserRouter>
    )
  }

  if (error) {
    return <LoginPage error={error.message} />
  }

  return <LoginRedirect />
}
