import classnames from 'classnames'
import React from 'react'
import { useTranslation } from '../../lib/i18n'
import { PortcallVesselIcon, TrafficVesselIcon } from '../../UI/Icons/Vessel'
import styles from './Legend.module.scss'
import { isShowCase } from '../../config'

function TrafficEntry() {
  const { t } = useTranslation()

  return (
    <div className={styles.entry}>
      <span className={styles.icon}>
        <TrafficVesselIcon />
      </span>
      <span className={styles.text}>{t('ShipTracker.Map.Legend.OtherDestinations')}</span>
    </div>
  )
}

type LegendProps = { name: string; withListOffset?: boolean }
export const Legend: React.FC<LegendProps> = ({ name, withListOffset = true }) => {
  const { t } = useTranslation()

  return (
    <div className={classnames(styles.legendBox, { [styles.legendBoxWithListOffset]: withListOffset })}>
      <div className={styles.entry}>
        <span className={styles.icon}>
          <PortcallVesselIcon />
        </span>
        <span className={styles.text}>
          {t('ShipTracker.Map.Legend.PortcallVessel', `${name}${isShowCase ? ' (-/+ 48h)' : ''}`)}
        </span>
      </div>
      <TrafficEntry />
    </div>
  )
}
