import { eqNumber } from 'fp-ts/es6/Eq'
import { pipe } from 'fp-ts/es6/function'
import { map, fromNullable, Option, getEq } from 'fp-ts/es6/Option'
import { useState, useEffect } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { Pages } from '../../constants'

export const selectedVesselPath = (mmsi: number | string) => `${Pages.MAP}/vessels/${mmsi}`

export const selectedVesselDetailsPath = (mmsi: number | string) => `${selectedVesselPath(mmsi)}/details`

export const equalsMMSIOption = getEq(eqNumber).equals

export const findMMSIMatch = (pathname: string) =>
  pipe(
    matchPath<{ mmsi?: string }>(pathname, { path: `${Pages.MAP}/vessels/:mmsi` })?.params.mmsi,
    fromNullable
  )

export const useMMSIMatcher = () => {
  const [mmsiMatch, setMMSIMatch] = useState<Option<number>>(
    pipe(findMMSIMatch(window.location.pathname), map(parseInt))
  )
  const { pathname } = useLocation()

  useEffect(() => {
    const match = pipe(findMMSIMatch(pathname), map(parseInt))

    if (!equalsMMSIOption(match, mmsiMatch)) {
      setMMSIMatch(match)
    }
  }, [pathname, mmsiMatch])

  return { mmsiMatch }
}
