import { useEffect } from 'react'
import { AllVesselProperties, VesselSource } from '../../Domain/Vessel'
import { usePrevious } from '../../lib/hooks/usePrevious'
import { useMixpanel } from '../../lib/mixpanel/MixpanelContext'
import { vesselState } from '../Traffic/localVesselStore'

export function useTrackAddHandPicked(properties?: AllVesselProperties & { destinationPortName?: string }) {
  const { track, setUserProperties } = useMixpanel()

  const prevProperties = usePrevious(properties)

  useEffect(() => {
    // Track Hand Picked vessels
    if (
      properties !== undefined &&
      properties.source === VesselSource.HAND_PICKED &&
      prevProperties?.source !== VesselSource.HAND_PICKED &&
      prevProperties?.mmsi === properties.mmsi
    ) {
      const subscription = vesselState.subscribe(({ handPicked }) => {
        track('Add to HandPicked', { destination: properties?.port ?? properties?.destinationPort ?? 'n/a' })
        setUserProperties('totalNumberOfHandPicked', handPicked.length)
      })

      return subscription.unsubscribe.bind(subscription)
    }
  }, [properties, prevProperties, setUserProperties, track])
}
