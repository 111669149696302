import { PropsWithChildren, useEffect } from 'react'
import { useFromFetch } from '../lib/useFromFetch'
import { fetchSubscriptions } from '../Api/Notification/Subscription/fetchSubscriptions'
import { actions } from './Traffic/useNotificationSubscriptions'

export function NotificationSubscriptions({ children }: PropsWithChildren<{}>) {
  const { isLoading, data } = useFromFetch(fetchSubscriptions)

  useEffect(() => {
    data && actions.init(data)
  }, [data])

  if (isLoading || data === undefined) {
    return null
  }

  return <>{children}</>
}
