import React, { useCallback, useMemo } from 'react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import Downshift from 'downshift'
import {
  getBestArrivalTime,
  createMMSI,
  HandPickedVessel,
  PortVessel,
  VesselSource,
  formatArrivalTime,
  isActualArrivalTime,
} from '../../../Domain/Vessel'
import { flow, pipe } from 'fp-ts/es6/function'
import classnames from 'classnames'
import { fold, fromNullable, none } from 'fp-ts/es6/Option'
import styles from './VesselListing.module.scss'
import { useFavoritesFilter } from '../useVesselTraffic'
import { findSubscription, useEditSubscription } from '../../SelectedVesselContent/useEditSubscription'
import { Ticker } from '../../../UI/Ticker/Ticker'
import { useTranslation } from '../../../lib/i18n'
import { actions } from '../localVesselStore'
import { useConfirmationModal } from '../../../UI/Modal/ConfirmationModal'
import { useMixpanel } from '../../../lib/mixpanel/MixpanelContext'
import { useNotificationSubscriptions } from '../useNotificationSubscriptions'
import { Subscription } from '../../../Domain/Notification/Subscription'
import { useAuth0 } from '@auth0/auth0-react'
import { toast } from 'react-toastify'
import { DURATION, ErrorToast, SuccessToast } from '../../../UI/Toast'
import { useHistory } from 'react-router'
import { Pages } from '../../../constants'
import { selectedVesselPath, useMMSIMatcher } from '../../helpers/paths'

export type VesselItem = PortVessel | HandPickedVessel

type SubscriptionTogglerProps = {
  subscriptions: Subscription[]
  portcallId: PortVessel['properties']['portcallId']
  children: (props: {
    isOn: boolean
    isPending: boolean
    toggle: (e: React.SyntheticEvent) => void
  }) => React.ReactElement
}

const SubscriptionToggler = ({ portcallId, subscriptions, children }: SubscriptionTogglerProps) => {
  const { track, incrementUserProperty } = useMixpanel()
  const { t } = useTranslation()
  const subscriptionId = portcallId ? findSubscription(portcallId, subscriptions) : none

  const { hasSubscription, isPending, addSubscription, deleteSubscription } = useEditSubscription(
    portcallId,
    subscriptionId
  )

  const handleToggleSubscription = useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation()

      if (hasSubscription) {
        deleteSubscription()
        track('Set alerts', {
          updateAlerts: 'remove alert',
        })
        incrementUserProperty('totalNumberOfAlerts', -1)
      } else {
        // add Notification
        addSubscription()
        track('Set alerts', {
          updateAlerts: 'set alert',
        })
        incrementUserProperty('totalNumberOfAlerts', 1)
        toast.success(<SuccessToast>{t('ShipTracker.Toast.SubscriptionAdded.Message')}</SuccessToast>, {
          autoClose: DURATION.MEDIUM,
        })
      }
    },
    [hasSubscription, addSubscription, deleteSubscription, track, incrementUserProperty, t]
  )

  return children({
    toggle: handleToggleSubscription,
    isOn: hasSubscription,
    isPending,
  })
}

type VesselListingListItemProps = {
  item: VesselItem
  subscriptions: Subscription[]
  modals: { askForSignup: (callback: () => void) => void; askForConfirmation: (callback: () => void) => void }
}
const VesselListingListItem: React.FC<VesselListingListItemProps> = ({
  item: { properties },
  subscriptions,
  modals: { askForSignup, askForConfirmation },
}) => {
  const { t } = useTranslation()
  const { isAuthenticated } = useAuth0()
  const { incrementUserProperty, register } = useMixpanel()
  const { favorites, handleChangeFavorite } = useFavoritesFilter()

  const isHandpicked = properties.source === VesselSource.HAND_PICKED

  const { title, bestArrivalTime, isFavorite, handPickedNextPort, subscriptionPortcallId } = useMemo(() => {
    const portcallId =
      properties.source === VesselSource.PORT_VESSEL ? properties.portcallId : properties?.eventPortcallId

    return {
      title: properties.shipName,
      bestArrivalTime: getBestArrivalTime(properties),
      isFavorite: favorites.includes(createMMSI(properties.mmsi)),
      handPickedNextPort:
        properties.source === VesselSource.HAND_PICKED && (properties.port || properties.destinationPort),
      subscriptionPortcallId: isAuthenticated && portcallId ? portcallId : null,
    }
  }, [properties, favorites, isAuthenticated])

  const handleToggleFavorite = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      const newIsFavorite = !isFavorite
      handleChangeFavorite(createMMSI(properties.mmsi), newIsFavorite)
      if (newIsFavorite) {
        toast.success(<SuccessToast>{t('ShipTracker.Toast.FavoritesAdded.Message')}</SuccessToast>, {
          autoClose: DURATION.MEDIUM,
        })
      }
    },
    [properties.mmsi, isFavorite, handleChangeFavorite, t]
  )

  const handleDisabledSubscriptionModal = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()

      if (!isAuthenticated) {
        askForSignup(() => {
          register({ signupMethod: 'envelope icon' })
        })
      } else {
        toast.error(<ErrorToast>{t('ShipTracker.Toast.DisabledSubscription.Message')}</ErrorToast>, {
          autoClose: DURATION.MEDIUM,
        })
      }
    },
    [isAuthenticated, askForSignup, register, t]
  )

  const arrivalTimeValue = bestArrivalTime?.value
  const arrivalTimeOffset = bestArrivalTime?.offset

  return (
    <>
      <div className={classnames(styles.innerItem, styles.innerItemInfo, { [styles.handPickedItem]: isHandpicked })}>
        {isHandpicked && (
          <i
            onClick={e => {
              e.stopPropagation()
              askForConfirmation(() => {
                actions.removeHandPicked(createMMSI(properties.mmsi))
                incrementUserProperty('totalNumberOfHandPicked', -1)
              })
            }}
            className={classnames(styles.closeButton, styles.iconSvg, styles.deleteVessel)}
          />
        )}
        <Ticker className={styles.text}>{title}</Ticker>
        {handPickedNextPort && (
          <span className={(styles.text, styles.textDestination)}>(dest. {handPickedNextPort})</span>
        )}
      </div>
      <div className={classnames(styles.innerItem, styles.innerItemInfo)}>
        {arrivalTimeValue !== undefined ? (
          <>
            <span className={classnames(styles.text, styles.etaLabel)}>
              {isActualArrivalTime(bestArrivalTime)
                ? t(bestArrivalTime.labelKey).toUpperCase()
                : `${t('ShipTracker.Common.ETA')} ${t('ShipTracker.Common.Port')}`.toUpperCase()}
            </span>
            <Ticker className={styles.text}>{formatArrivalTime(arrivalTimeValue, arrivalTimeOffset)}</Ticker>
          </>
        ) : (
          <span className={styles.text}>N/A</span>
        )}
      </div>
      <>
        <button className={classnames(styles.innerItem, styles.innerItemAction)} onClick={handleToggleFavorite}>
          <i className={classnames(styles.iconSvg, styles.fav, { [styles.checked]: isFavorite })} />
        </button>
        {subscriptionPortcallId ? (
          <SubscriptionToggler
            key={subscriptionPortcallId}
            subscriptions={subscriptions}
            portcallId={subscriptionPortcallId}
          >
            {({ isOn, isPending, toggle }) => (
              <button
                className={classnames(styles.innerItem, styles.innerItemAction)}
                onClick={!isPending ? toggle : undefined}
              >
                <i className={classnames(styles.iconSvg, styles.mail, { [styles.checked]: isOn })} />
              </button>
            )}
          </SubscriptionToggler>
        ) : (
          <button
            className={classnames(styles.innerItem, styles.innerItemAction, styles.disabled)}
            onClick={handleDisabledSubscriptionModal}
          >
            <i className={classnames(styles.iconSvg, styles.mail)} />
          </button>
        )}
      </>
    </>
  )
}

type VesselListingListProps = {
  portcalls: PortVessel[]
  handpickedVessels: HandPickedVessel[]
}

export const VesselListingList: React.FC<VesselListingListProps> = ({ handpickedVessels, portcalls }) => {
  const { t } = useTranslation()
  const shouldDisplaySeparator = handpickedVessels.length !== 0 && portcalls.length !== 0
  const combinedList = useMemo(() => [...handpickedVessels, ...portcalls], [portcalls, handpickedVessels])
  const { subscriptions } = useNotificationSubscriptions()
  const { mmsiMatch } = useMMSIMatcher()
  const history = useHistory()

  const [ConfirmationModalContainer, askForConfirmation] = useConfirmationModal({
    title: t('ShipTracker.Modal.DeleteHandPicked.Title'),
    text: t('ShipTracker.Modal.DeleteHandPicked.Text'),
    confirmText: t('ShipTracker.Modal.DeleteHandPicked.Confirm'),
  })
  const [SignupModalContainer, askForSignup] = useConfirmationModal({
    title: t('ShipTracker.Modal.Signup.Title'),
    text: t('ShipTracker.Modal.Signup.Text'),
    confirmText: t('ShipTracker.Modal.Signup.Confirm'),
  })

  return (
    <>
      <Downshift<VesselItem>
        initialIsOpen
        highlightedIndex={pipe(
          mmsiMatch,
          fold(
            () => null,
            mmsi => combinedList.findIndex(item => item.properties.mmsi === mmsi)
          )
        )}
        itemToString={item => item?.properties.mmsi.toString() || ''}
        onSelect={flow(
          fromNullable,
          fold(
            () => history.push(Pages.MAP),
            item => {
              history.push(selectedVesselPath(item.properties.mmsi))
            }
          )
        )}
      >
        {downshift => (
          <div className={styles.listing}>
            <OverlayScrollbarsComponent
              {...downshift.getMenuProps({ className: styles.listingInner })}
              options={{ scrollbars: { autoHide: 'scroll' } }}
            >
              {handpickedVessels.map((item, index) => (
                <div
                  key={`handpicked-item-${index}`}
                  {...downshift.getItemProps({
                    item,
                    index,
                    className: classnames(styles.item, {
                      [styles.highlighted]: index === downshift.highlightedIndex,
                    }),
                  })}
                >
                  <VesselListingListItem
                    subscriptions={subscriptions}
                    item={item}
                    modals={{ askForConfirmation, askForSignup }}
                  />
                </div>
              ))}
              {shouldDisplaySeparator && <hr className={styles.separator} key="separator" />}
              {portcalls.map((item, index) => {
                const calculatedIndex = handpickedVessels.length + index
                return (
                  <div
                    key={`portcall-item-${calculatedIndex}`}
                    {...downshift.getItemProps({
                      item,
                      index: calculatedIndex,
                      className: classnames(styles.item, {
                        [styles.highlighted]: calculatedIndex === downshift.highlightedIndex,
                      }),
                    })}
                  >
                    <VesselListingListItem
                      subscriptions={subscriptions}
                      item={item}
                      modals={{ askForConfirmation, askForSignup }}
                    />
                  </div>
                )
              })}
            </OverlayScrollbarsComponent>
          </div>
        )}
      </Downshift>
      <ConfirmationModalContainer />
      <SignupModalContainer />
    </>
  )
}
