import { SUPPORT_EMAIL_ADDRESS } from '../constants'

const localTranslations = {
  en: {
    ShipTracker: {
      Common: {
        Port: 'Port',
        ATA: 'ATA',
        ATA_PBP: 'ATA PBP',
        ATA_PORT: 'ATA Port',
        ATA_ANCHOR_AREA: 'ATA Anchor area',
        ETA: 'ETA',
        ETA_PBP: 'ETA PBP',
        ETA_BERTH: 'ETA BERTH',
        AIS: 'AIS',
        Loading: 'Loading…',
        Processing: 'Processing…',
        PleaseWait: 'Please wait...',
        Free: 'Free',
        Account: 'Account',
        Month: 'Month',
        NotAvailable: 'N/A',
        Canceled: 'Canceled',
        Signup: 'Sign up',
        Upgrade: 'Upgrade',
        Cancel: 'Cancel',
        Delete: 'Delete',
        Calculated: 'Calculated',
        Predicted: 'Predicted',
        Reported: 'Reported',
        Buy: 'Buy',
        Back: 'Back',
        ReceivingToken: 'Receiving token',
        Success: 'Success',
      },
      Menu: {
        ShowMap: 'Show map',
        DarkMode: 'Dark mode',
        Support: 'Support',
        AvailablePorts: 'Available ports',
        AboutShipTracker: 'About Shiptracker',
        ApiCreditsStore: 'Buy API Credits',
        WebSubscriptions: 'Buy Web Subscription',
        GlobalETAs: 'ETA predictions to other ports?',
        LegalTerms: 'Legal Terms',
        Search: {
          RefineResultsNotice:
            'Please be advised that there may be more vessels matching your query. Please refine your query further if you are interested in those vessels.',
          SearchByIdentifier: 'Search for ship name, IMO, ENI, MMSI or USCG',
          SearchByVesselIdentifier: 'Vessel name, IMO, ENI, MMSI or USCG',
        },
      },
      SupportPage: {
        Title: 'Support',
        Content: `If you need product support, please email us at <a href="mailto:${SUPPORT_EMAIL_ADDRESS}">${SUPPORT_EMAIL_ADDRESS}</a>.
        \nWe typically reply within one business day.
        \n\n<strong>Currently, Shiptracker only supports  <a href='https://www.google.com/chrome/'>Chrome</a> or <a href='https://www.mozilla.org/firefox/new/'>Firefox</a> browsers. Additionally, incognito mode may cause problems and is advised to be disabled.</strong>`,
      },

      WebSubscriptionsPage: {
        Title: 'Web subscription',
        FetchError: "Sorry, couldn't fetch plans at this time.",
      },

      AboutShipTrackerPage: {
        Title: 'About Shiptracker',
        Content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus tristique dictum arcu sed consequat. Nullam congue viverra est, quis viverra turpis porta quis.\n\nPraesent tempus lacinia ligula, convallis sodales nulla aliquam ut. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tellus sapien, ullamcorper in sapien sed, lacinia ornare dui. Aliquam id ipsum et velit lobortis luctus vitae a mauris. Vestibulum nec lobortis tellus. Nullam eget vulputate odio. Praesent id tristique ante. Praesent fermentum et sem at fringilla.`,
      },

      ApiCreditsPage: {
        SelectApiCreditBundle: 'Select your API credits bundle',
      },

      AvailablePortsPage: {
        Title: 'Available ports',
        CreateMyFreeAccount: 'Create my free account',
        UpgradeToPremium: 'Upgrade to Shiptracker Premium',
        GoToGlobalShiptracker: 'Go to Shiptracker Global',
        AvailablePortsDescription:
          'We currently can predict ETAs to the ports listed below.\nFor all other ports that are not on the list, we provide only reported ETAs.',
        ShowCaseAvailablePortsDescription:
          'The port of Rotterdam is the showcase port for Shiptracker with all functionalities unlocked. If you want to see vessel arrival times to other ports, go to the global Shiptracker website. Below is the list of ports that are currently available in Shiptracker Global. We add new ports on a weekly basis',
      },
      Controls: {
        OtherDestinations: 'Other destinations',
      },
      VesselListing: {
        FilterList: 'Filter List',
        OnlyShowManualFleet: 'Only show manual fleet',
        NotificationNotAvailable: 'Notification N/A',
        SendArrivalNotification: 'Send arrival notification',
        ApplyFilters: 'Apply',
        FilterFlagState: 'Filter Flag state',
        FlagState: 'Flag state',
        ChooseDestinationPort: 'Choose destination port',
        CountriesDropdown: {
          startTyping: 'Start typing to search for flag states',
          minimumLength: (length: string) => `Too few characters. Please type ${length} characters or more.`,
          loading: 'Loading…',
        },
        ShipTypeCategory: {
          UNSPECIFIED_SHIPS: 'Unspecified Ships',
          NAVIGATION_AIDS: 'Navigation Aids',
          FISHING: 'Fishing',
          TUG_AND_SPECIAL_CRAFT: 'Tug and Special Craft',
          HIGH_SPEED_CRAFT: 'High Speed Craft',
          PASSENGER_VESSELS: 'Passenger Vessels',
          CARGO_VESSELS: 'Cargo Vessels',
          TANKERS: 'Tankers',
          PLEASURE_CRAFT: 'Pleasure Craft',
        },
      },

      Drawer: {
        Navigation: {
          VesselTrace: 'VESSEL TRACE',
          EmmisionReport: 'EMMISION REPORT',
        },
        Actions: {
          AddVessel: 'Add vessel to list',
        },
      },
      Email: {
        placeholder: 'john@doe.com',
      },
      Account: {
        FindPort: 'Find port',
        MyAccount: 'My Account',
        Login: 'Log in',
        Logout: 'Log out',
        LoginPage: {
          Title: 'Please login with your account',
          Text: 'Please fill in your e-mail address and a password.',
        },
        Signup: {
          title: 'Are you a new customer?',
          createAccount: 'Create account',
        },

        LegalTerms: {
          Title: 'Accept legal terms & conditions',
          AcceptTermsAndConditions: 'I accept the terms and conditions',
          MustAcceptTerms: 'Please accept our legal terms and conditions',
          Continue: 'Continue',
          ContinueToMap: 'Continue to map',
        },

        PortDropdown: {
          startTyping: 'Start typing to search for ports',
          minimumLength: (length: string) => `Too few characters. Please type ${length} characters or more.`,
          loading: 'Loading…',
        },
      },
      Map: {
        Marker: {
          DestinationPort: 'Dest. Port',
          NextBerth: 'Next Berth',
          TerminalOrBerth: 'Terminal/Berth',
          ETAPBPReported: 'ETA PBP (Reported)',
          ETAPortReported: 'ETA Port (Reported)',
          ETAPBPCalculated: 'ETA PBP (Calculated)',
          ETAPortPredicted: 'ETA Port (Predicted)',
          ETAPBP: 'ETAPBP',
          NEXT_BERTH: 'Next Berth',
          NEXT_PORT: 'Next Port',
          ETA_BERTH: 'ETA Berth',
          ETA_PORT: 'ETA Port',
          NoDetails: 'No details',
          NotAvailable: 'Not available',
          Speed: 'Speed',
          Type: 'Vessel Type',
          Subtype: 'Subtype',
          Unknown: 'Unknown',
          CallSign: 'Call sign',
          Draught: 'Draught',
          FlagState: 'Flag state',
          BuiltYear: 'Built Year',
        },
        Legend: {
          PortcallVessel: (port: string) => `To ${/^port.+/i.test(port) ? 'the ' : ''}${port}`,
          OtherDestinations: 'Other destinations',
        },
        VesselDetails: {
          Details: 'Details',
          PastTrackAndRouteForecast: 'Past Track & Route Forecast',
        },
      },
      TrafficFilter: {
        Panel: {
          ApplyFilter: 'Apply Filter',
          ShowPortcallsLabel: 'Show Portcalls',
          ShowTrafficLabel: 'Show Traffic',
          PortOfRotterdam: 'PORT OF ROTTERDAM',
          Explanation: `For this Port we can filter out all\ntraffic so you will remain with only\nthe relevant Portcalls to this Port.\n\nPlease make a selection below:`,
        },
      },

      Toast: {
        NotSignedIn: {
          Header: 'Not signed in',
          Message: 'you need to be signed in to view this page',
        },
        UpdateCreditCardError: {
          Header: 'Oops, something went wrong',
          Message: 'We failed to update your card details, please try again or contact support',
        },
        DisabledSubscription: {
          Message: `ATA Notification not possible, because at this time we don't know/support the destination of the vessel`,
        },
        CanceledSubscription: {
          Header: 'Success',
          Message: 'Your subscription has been canceled!',
        },

        CancelSubscriptionError: {
          Header: 'Oops, something went wrong',
          Message: 'We failed to cancel your subscription, please try again or contact support',
        },

        FindLocation: {
          Header: 'Location',
          Message: 'Finding your location',
        },

        LocationTimeout: {
          Header: 'Timeout',
          Message: 'We failed to determine you location',
        },
        CreateSubscriptionError: {
          Header: 'Oops, something went wrong',
          Message: 'We failed to create a subscription, please try again or contact support',
        },

        SubscriptionTokenError: {
          Header: 'Oops, something went wrong',
          Message: (time: string) =>
            `We failed to update the system, the browser will automatically refresh in ${time}`,
        },

        CreatedSubscription: {
          Header: 'Success',
          Message: 'Your subscription has been created!',
        },
        EmailVerificationError: {
          Header: 'Email verification',
          Message: (email: string) =>
            `A verification mail has been mailed to: ${email}. Please verify your email before logging in.`,
        },
        EmailVerified: {
          Header: 'Success',
          Message: 'Your email was verified. You can continue using the application.',
        },
        EmailAlreadyVerified: {
          Header: 'This email is already verified',
          Message: `The verification link has already been used. You can now log in. If you think it's a mistake please don't hesistate to contact us: ${SUPPORT_EMAIL_ADDRESS}`,
        },
        SubscriptionAdded: {
          Message: 'You will receive email notification when this vessel arrives',
        },
        FavoritesAdded: {
          Message: 'This vessel has been added to your favorite list',
        },
        VesselNotFound: {
          Header: (mmsi: string) => `Vessel (${mmsi}) not found`,
          Message: `The location of the vessel you've searched for can't be determined`,
        },
        VesselHidden: {
          Header: (mmsi: string) => `Vessel (${mmsi}) is hidden`,
          Message: `The Vessel you've searched for is hidden because of your filter settings, please adjust your filters`,
        },

        UpdatedCredits: {
          Header: `Successs`,
          Message: `Your payment is processed, your total credits will be updated withing an hour`,
        },

        UpdatedCreditsError: {
          Header: 'Oops, something went wrong',
          Message: () => `We failed to process your request, please try again or contact support`,
        },

        PaymentSucceeded: {
          Header: `Great news!`,
          Message: `Your payment was successful`,
        },

        PayementError: {
          Header: 'Oops, something went wrong',
          Message: 'Your payment failed, please try again or contact support',
        },
      },
      Modal: {
        Default: {
          Confirmation: {
            Cancel: 'Cancel',
            Confirm: 'Confirm',
          },
        },
        DeleteHandPicked: {
          Title: 'Remove vessel from the list?',
          Text: 'By removing this vessel from the list, you’ll no longer be able to use it in the filters.',
          Confirm: 'Remove',
        },
        Signup: {
          Title: 'Notify on arrival?',
          Text:
            'Want to stay up to date on the arrival of your chosen ship? Sign up and receive free email notifications when the vessel arrives at the port.',
          Confirm: 'Sign up',
        },
        RedirectToApiFlow: {
          Title: 'Great, you have an account already!',
          Text:
            'Before you can generate the API key, we will need to get some additional information from you. Since you already have an account, you will land in step 3 of the API onboarding flow.',
          Confirm: 'Continue',
        },
      },
    },
  },
}

type Template = (...vars: string[]) => string
type Translations = { [key: string]: Translations | string | Template }

function translate(translations: Translations, path: string): string | Template {
  let entry: Translations | string | Template = translations

  for (let p of path.split('.')) {
    if (typeof entry !== 'string' && typeof entry !== 'function' && p in entry) {
      entry = entry[p]
    } else {
      break
    }
  }

  return typeof entry === 'string' || typeof entry === 'function' ? entry : path
}

export const useTranslation = () => ({
  t: (entry: string, ...args: (string | number)[]) => {
    if (process.env.NODE_ENV === 'test') {
      return entry
    }
    const res = translate(localTranslations.en, entry)

    if (typeof res === 'function') {
      return res.apply(
        null,
        args.map(a => a.toString())
      )
    }

    return res
  },
})
