import { BehaviorSubject } from 'rxjs'
import { MMSI } from '../../Domain/Vessel'

type Search = { mmsi: MMSI; query: string }

export const searchedVessel = new BehaviorSubject<Search | undefined>(undefined)

export const setSearched = (search: Search) => searchedVessel.next(search)

export const clearSearched = () => searchedVessel.next(undefined)
