import { store } from './../dataStore'
import { Option } from 'fp-ts/es6/Option'
import { Unlocode } from './Port'
import { ZonedDateTime } from './DateTime'

export enum SystemOfMeasurement {
  Metric = 'Metric',
  Imperial = 'Imperial',
}

export type User = Readonly<{ port: Option<Unlocode>; id: string }>

export enum MetadataOption {
  'NO_BANNERS' = 'no_banners',
  'CUSTOM_STYLES' = 'custom_styles',
}

export type Metadata = {
  name?: string
  givenName?: string
  familyName?: string
  defaultPort?: Unlocode
  createdAt: ZonedDateTime
  options: MetadataOption[]
  measurementUnit: SystemOfMeasurement
}

export type MetadataJson = Omit<Metadata, 'measurementUnit'> & {
  measurementUnit?: SystemOfMeasurement
}

export const fetchMeasurementUnit = async (isAuthenticated: boolean) =>
  isAuthenticated ? store.metadata.fetch().then(({ measurementUnit }) => measurementUnit) : SystemOfMeasurement.Metric
