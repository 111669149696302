import React from 'react'

import styles from './FullScreenOverlay.module.scss'
import { CloseButton, CloseButtonTheme } from '../CloseButton'

type FullScreenOverlayProps = Readonly<{
  title: React.ReactNode
  onClose: () => void
}>

const buttonTheme: CloseButtonTheme = {
  button: styles.fullScreenOverlayCloseButton,
  icon: styles.fullScreenOverlayCloseButtonIcon,
}

export const FullScreenOverlay: React.FC<FullScreenOverlayProps> = ({ children, title, onClose }) => (
  <>
    <div className={styles.fullScreenOverlay} role="dialog">
      <div className={styles.fullScreenOverlayTopBar}>
        <h2 className={styles.fullScreenOverlayTitle}>{title}</h2>
        <CloseButton
          text={<span className={styles.fullScreenOverlayCloseButtonText}>Close</span>}
          onClose={onClose}
          theme={buttonTheme}
        />
      </div>
      <div className={styles.fullScreenOverlayContent}>{children}</div>
    </div>
  </>
)
