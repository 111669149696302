import React, { useContext, createContext, PropsWithChildren, useState, useEffect } from 'react'
import { Option, none, some, getEq, map, isNone } from 'fp-ts/es6/Option'
import { Port } from '../Domain/Port'
import { noop } from 'rxjs'
import { pipe } from 'fp-ts/es6/function'
import { usePrevious } from '../lib/hooks/usePrevious'
import { store } from '../dataStore'

type State = { portOption: Option<Port>; setPortOption: (port: Port) => void }

const Context = createContext<State>({ portOption: none, setPortOption: noop })

export const useDestinationPortContext = () => useContext<State>(Context)

type DestinationPortProviderProps = PropsWithChildren<{ portOption: Option<Port> }>

const compareOptionalPorts = getEq({ equals: ({ port: portA }: Port, { port: portB }: Port) => portA === portB }).equals

export function DestinationPortProvider({ children, portOption: initialPortOption }: DestinationPortProviderProps) {
  const [portOption, setPortOption] = useState<State['portOption']>(initialPortOption)

  const previousOption = usePrevious<State['portOption']>(portOption) || none

  useEffect(() => {
    if (isNone(previousOption)) {
      return
    }

    const isEqual = compareOptionalPorts(portOption, previousOption)

    if (!isEqual) {
      pipe(
        portOption,
        map(({ port }) => {
          store.metadata.update({ defaultPort: port })
        })
      )
    }
  }, [portOption, previousOption])

  return (
    <Context.Provider
      value={{
        portOption,
        setPortOption: p => pipe(p, some, setPortOption),
      }}
    >
      {children}
    </Context.Provider>
  )
}
