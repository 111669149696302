import React, { useState, useCallback } from 'react'
import { MapboxProvider } from './Mapbox'
import { Map as Mapbox } from 'mapbox-gl'
import { Port, portCenter } from '../Domain/Port'
import { Option, fold, getOrElse } from 'fp-ts/es6/Option'
import { pipe, constNull, constant } from 'fp-ts/es6/function'
import { DEFAULT_CENTER } from './constants'
import { InitializeMap } from './InitializeMap'
import { useShowCaseTraffic } from './Traffic/useVesselTraffic'
import { VesselListing, VESSEL_LISTING_INITIAL_VISIBILITY } from './Traffic/VesselListing/VesselListing'
import { NotificationSubscriptions } from './NotificationSubscriptions'
import { useMapDetailMarkers, MapMarkers } from './Marker/MapMarkers'
import { TrafficAndPortcallsControls } from './Controls/Controls'
import { Legend } from './Controls/Legend'
import { fetchPortVisitsPublic } from '../Api/Port/fetchVisitsPublic'
import { LayersDropdown } from './Controls/LayersDropdown'
import { useResponsiveness } from '../lib/hooks/useResponsiveness'

type ShowCaseProps = { port: Port; mapbox: Option<Mapbox> }

const mapCenter = (port: Port): [number, number] => pipe(portCenter(port), getOrElse(constant(DEFAULT_CENTER)))

export const AuthenticatedShowCase = ({ port, mapbox }: ShowCaseProps) =>
  pipe(
    mapbox,
    fold(constNull, mapbox => (
      <MapboxProvider mapbox={mapbox}>
        <NotificationSubscriptions>
          <InitializeMap center={mapCenter(port)}>
            <Map port={port} />
          </InitializeMap>
        </NotificationSubscriptions>
      </MapboxProvider>
    ))
  )

export const ShowCase: React.FC<ShowCaseProps> = ({ port, mapbox }) =>
  pipe(
    mapbox,
    fold(constNull, mapbox => (
      <MapboxProvider mapbox={mapbox}>
        <InitializeMap center={mapCenter(port)}>
          <Map port={port} />
        </InitializeMap>
      </MapboxProvider>
    ))
  )

function Map({ port }: { port: Port }) {
  const { isMobile } = useResponsiveness()
  const { detailMarkerData } = useMapDetailMarkers()
  const [isListVisible, setIsListVisible] = useState(VESSEL_LISTING_INITIAL_VISIBILITY)
  const { portcalls, traffic, handPicked, displayState, totalPortVessels } = useShowCaseTraffic(
    port.port,
    useCallback(() => fetchPortVisitsPublic(port.port), [port.port])
  )

  const [lng, lat] = port.center?.coordinates ?? DEFAULT_CENTER

  return (
    <>
      <MapMarkers
        portcalls={portcalls}
        handPicked={handPicked}
        traffic={traffic}
        displayState={displayState}
        detailMarkerData={detailMarkerData}
        port={port}
      />
      {!isMobile && (
        <>
          <TrafficAndPortcallsControls port={{ center: [lng, lat], name: port.name, port: port.port }} />
          <LayersDropdown />
          <Legend name={port.name} withListOffset={isListVisible} />
        </>
      )}
      <VesselListing
        totalPortVessels={totalPortVessels}
        port={port}
        portcalls={portcalls}
        handPickedVessels={handPicked}
        onListVisibilityChanged={setIsListVisible}
      />
    </>
  )
}
