import React from 'react'
import { ControlStripButton, ControlStripEntry } from '../../UI/ControlStrip/ControlStrip'
import { Checkmark } from '../../UI/Checkbox/Checkbox'
import styles from './Controls.module.scss'
import { useTranslation } from '../../lib/i18n'
import { useMixpanel } from '../../lib/mixpanel/MixpanelContext'
import { useTrafficFilter } from '../Traffic/useVesselTraffic'

export function DisplayTrafficControl() {
  const { t } = useTranslation()
  const { track } = useMixpanel()
  const { isShowingTraffic, handleSetShowTrafficFilterActive } = useTrafficFilter()

  const toggleShowingTraffic = () => {
    const newIsShowingTraffic = !isShowingTraffic
    handleSetShowTrafficFilterActive(newIsShowingTraffic)
    track(`Toggle - Other Destinations ${newIsShowingTraffic ? 'On' : 'Off'}`)
  }

  return (
    <ControlStripEntry>
      <ControlStripButton onClick={toggleShowingTraffic}>
        <Checkmark checked={isShowingTraffic}>
          <span className={styles.checkboxLabel}>{t('ShipTracker.Controls.OtherDestinations')}</span>
        </Checkmark>
      </ControlStripButton>
    </ControlStripEntry>
  )
}
