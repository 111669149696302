import { useMixpanel } from '../lib/mixpanel/MixpanelContext'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { EmailVerified, DURATION, EmailAlreadyVerified } from '../UI/Toast'

const verifiedEmailRegex = /^Your email was verified.(.*)$/
const alreadyVerifiedEmailRegex = /^This URL can be used only once(.*)$/

export function useHandleEmailVerificationSuccess() {
  const { track, setUserProperties, register } = useMixpanel()
  const {
    location: { search },
  } = window

  useEffect(() => {
    const message = new URLSearchParams(search).get('message')

    if (message && message.search(verifiedEmailRegex) > -1) {
      toast.success(<EmailVerified />, { autoClose: DURATION.MEDIUM })
      const signupProperties = { emailVerified: true }
      setUserProperties(signupProperties)
      register(signupProperties)
      track('Email Verified')
    } else if (message && message.search(alreadyVerifiedEmailRegex) > -1) {
      toast.info(<EmailAlreadyVerified />, { autoClose: DURATION.MEDIUM })
    }
  }, [search, setUserProperties, register, track])
}
