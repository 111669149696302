import { useEffect, useRef } from 'react'
import { isShowCase } from '../../config'
import { store } from '../../dataStore'
import { MetadataOption } from '../../Domain/User'

const SHOW_CASE_CUSTOM_STYLESHEET = 'https://havendashboard.dpipreview.nl/css/portxchange.css'

function appendLink() {
  const cssLink = document.createElement('link')
  cssLink.setAttribute('rel', 'stylesheet')
  cssLink.setAttribute('href', SHOW_CASE_CUSTOM_STYLESHEET)
  document.head.appendChild(cssLink)
  return cssLink
}

export function useCustomStyleSheet(isAuthenticated: boolean) {
  const link = useRef<HTMLLinkElement>()

  useEffect(() => {
    if (isShowCase && isAuthenticated) {
      store.metadata.fetch().then(({ options }) => {
        if (options.includes(MetadataOption.CUSTOM_STYLES) && link.current === undefined) {
          link.current = appendLink()
        }
      })
    }
  }, [isAuthenticated])
}
