import { BehaviorSubject, noop } from 'rxjs'
import { filter, flatMap } from 'rxjs/operators'

type State = { isAuthenticated: boolean; getTokenSilently: () => Promise<string> }

export const client = new BehaviorSubject<State>({ isAuthenticated: false, getTokenSilently: () => new Promise(noop) })

export const initializeClient = client.next.bind(client)

export const getTokenSilently = client.pipe(
  filter(({ isAuthenticated }) => isAuthenticated),
  flatMap(({ getTokenSilently }) => getTokenSilently())
)
