import { useEffect, useState } from 'react'
import { from } from 'rxjs'
import { fetchPublicVesselDetails, fetchVesselDetails } from '../../Api/Vessel/fetchDetails'
import { isShowCase } from '../../config'
import { VesselMasterDataWithPictureUrl } from '../../Domain/Vessel'

export function useVesselDetails(mmsi: number) {
  const [details, setDetails] = useState<VesselMasterDataWithPictureUrl>()

  useEffect(() => {
    setDetails(undefined)

    const subscription = from(
      isShowCase ? fetchPublicVesselDetails(`MMSI${mmsi}`) : fetchVesselDetails(`MMSI${mmsi}`)
    ).subscribe(setDetails)

    return subscription.unsubscribe.bind(subscription)
  }, [mmsi])

  return details
}
