import { useAuth0 } from '@auth0/auth0-react'
import classnames from 'classnames'
import { SHIP_TRACKER_THEME_CSS, SHIP_TRACKER_THEME_DARK_CSS } from '../../constants'
import { useDarkMode } from '../../lib/hooks/useDarkMode'
import { useTranslation } from '../../lib/i18n'
import { CtaButton } from '../../UI/CtaButton/CtaButton'
import { Logo } from '../../UI/Logo'

import styles from './LoginPage.module.scss'

export function LoginPage({ error }: { error: string }) {
  const { loginWithRedirect } = useAuth0()
  const { isDarkMode } = useDarkMode()
  const { t } = useTranslation()

  return (
    <div
      className={classnames(styles.loginPage, SHIP_TRACKER_THEME_CSS, {
        [SHIP_TRACKER_THEME_DARK_CSS]: isDarkMode,
      })}
    >
      <div className={styles.header}>
        <Logo className={styles.logo} />
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <h1 className={styles.title}>{t('ShipTracker.Account.LoginPage.Title')}</h1>
          <p className={styles.text}>An error occurred when logging in:</p>
          <p className={styles.text}>{error}</p>
          <p className={styles.text}>Please try again.</p>
          <p className={styles.text}>
            <CtaButton className={styles.submitButton} onClick={() => loginWithRedirect({ max_age: 0 })}>
              Log in
            </CtaButton>
          </p>
        </div>
      </div>
    </div>
  )
}
